body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 20px;
  }
  
  h2, h3 {
    color: #333;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    margin-top: 10px;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 10px;
    text-align: center;
  }
  
  th {
    background-color: #35386d;
    color: white;
  }
  
  td select, td input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
  }
  
  button:hover {
    opacity: 0.9;
  }
  
  .add-btn {
    background-color: #28a745;
    color: white;
  }
  
  .remove-btn {
    background-color: #dc3545;
    color: white;
  }
  
  .save-btn {
    background-color: #FF485B;
    color: white;
  }
  
  .submit-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  @media (max-width: 768px) {
    td select, td input {
      font-size: 14px;
    }
  
    button {
      font-size: 14px;
      padding: 8px 12px;
    }
  }
  

  .model-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .model-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 45%;
  }
  
  .model-content h2 {
    margin-bottom: 15px;
  }
  
  .model-content select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .model-content button {
    margin-top: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .despselect{
    background-color: #fff;
    color: #333;
    border: solid 1px #dee2e6 !important;
    margin: 0;
  }
  /*
  .model-content button:first-child {
    background-color: #FF485B;
    color: white;
  }
  
  .model-content button:last-child {
    background-color: #fff;
    color: #333;
    border: solid 1px #dee2e6;
    margin: 0;
  }*/
  
  