/* FeeStructure.css */

/* General Styles */
.BxAllSide {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
}

.BxApple {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.AdinTitle h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Form Container */
.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.form-select,
.form-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  flex: 1 1 200px;
}

.form-select:disabled {
  background-color: #f0f0f0;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-row-btn {
  background-color: #3328a7;
  color: white;
}

.add-row-btn:hover {
  background-color: #3328a7;
}

/* Table Styles */
.table-responsive {
  overflow-x: auto;
}

.FeeStructure-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.FeeStructure-table th,
.FeeStructure-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.FeeStructure-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #333;
}

.FeeStructure-table tbody tr:hover {
  background-color: #f1f1f1;
}

.edit-btn {
  background-color: #2012c1;
  color: black;
  margin-right: 5px;
}

.edit-btn:hover {
  background-color: #15055d;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
}

.delete-btn:hover {
  background-color: #c82333;
}

.text-center {
  text-align: center;
  color: #777;
}
.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0 5px;
}

.edit-btn {
  color: #1b138e;
}

.delete-btn {
  color: #E53935;
}

.btn-icon:hover {
  opacity: 0.7;
}


