.containerfluid{
    max-width: 1400px;
    margin: 0 auto;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}
.containerfluidForm{
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}
.adminLogin{
    position: fixed;
    right: 0px;
    background: #ff485b;
    padding: 10px;
    border-radius: 4px 0px 0px 4px;
    color: #fff;
    font-size: 30px;
    line-height: 0;
    border: none;
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
}
.Bx-shadow{
    box-shadow: 1px 1px 6px 2px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.BrandLogo{
    font-size: 26px;
    font-weight: 600;
    color:#35386D;
}
.BrandLogo img{
    width: 60px;
}
.BrandLogo span{
    padding-left: 10px;
}
.Hicons{

}
.Hicons img{
    width: 22px;
}
.btn.loginBtn {
    background: #FF485B;
    border-color: #FF485B;
    color: #fff;
    font-size: 16px;
    padding: 8px 15px;
    margin-right: 10px;
}
.btn.loginBtn:hover {
    background: #FF485B;
    border-color: #FF485B;
    color: #fff;
    font-size: 16px;
    padding: 8px 15px;
}
.btn.siginBtn {
    background: #35386D;
    border-color: #35386D;
    color: #fff;
    font-size: 16px;
    padding: 8px 15px;
    margin-right: 10px;
}
.btn.siginBtn:hover {
    background: #35386D;
    border-color: #35386D;
    color: #fff;
    font-size: 16px;
    padding: 8px 15px;
}
.unvisity{
    width: 100%;
    background: #fff url(../../img/junt-university.jpg) no-repeat;
    height: 400px;
    background-size: cover;
    border-radius: 10px;
    border: solid 2px #35386D;
    box-shadow: 1px 1px 6px 2px rgb(0 0 0 / 20%);
}
.hmgPd{
    padding: 12px 0px !important;
}
.unvisityMt{
    margin-top: 100px;
}
.unvisityTitle h1{
    font-size: 50px;
    color: #35386D;
    font-weight: 700;
}
.unvisityTitle h2{
    font-size: 20px;
    color: #35386D;
    font-weight: 500;
}
.unvisityTitle p{
    font-size: 20px;
    color: #35386D;
    font-weight: 500;
}
.unvisityTitleAd p{
    font-size: 16px;
    color: #8A8787;
    font-weight: 400;
    margin: 0px 80px;
}
.frmBg{
    position: relative;
    padding: 10px;
    margin: 0px 80px;
    background: #EEEEEE;
    border-radius: 100px;
    box-shadow: 1px 1px 6px 2px rgb(0 0 0 / 20%);
}
.inputUnvisity{
    position: relative;
    border-radius: 100px !important;
    padding: 10px 20px;
    height: 50px;
    background: #fff;
    border: none;
}
.btn.GetBtn{
    z-index: 1;
    position: absolute;
    background: #FF485B;
    border-radius: 100px;
    font-size: 18px;
    color: #fff;
    right: 16px;
    top: 15px;
    padding: 6px 30px;
    text-transform: uppercase;
}
.btn.GetBtn:hover{
    background: #fff;
    border: solid 1px #FF485B;
    color: #FF485B;
}
.infsBg{
    background:#fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 6px 2px rgb(0 0 0 / 20%);
}
.instBgBar{
    background: #35386D;
    padding:6px 10px;
    color: #fff;
    border-radius: 4px;
    font-size: 22px;
}
.rightIcon{
    margin-bottom: 10px;
}
.rightIcon p{
    color: #FF485B;
    font-size: 20px;
    margin-left: 10px;
}
.rightIcon img{
    width: 20px;
    margin: 6px;
}
.AppltBg{
    position: relative;
    background: #EBF8FF;
    border-radius: 0px 0px 20px 20px ;
    height: 290px;
}
.AppltBg.AppltBgHeigth{
    border-radius: 0px;
    height: auto;
}
.loginUnBg{
    width: auto;
    position: relative;
    background: #fff;
    height: 100%;
    margin: 0px 30px;
    padding: 20px 50px;
    border-radius: 10px;
    box-shadow: 1px 1px 6px 2px rgb(0 0 0 / 20%);
}
.unLgnTitle h1{
    font-size: 54px;
    color: #35386D;
    font-weight: 700;
}
.unLgnTitle h2{
    font-size: 26px;
    color: #35386D;
}
.untyTitle h1{
    font-size: 40px;
    color: #35386D;
    font-weight: 700;
}
.FtrBg{
    text-align: center;
    padding: 10px 10px;
    color: #8A8787;
    background: #eee;
}
.AllLgnBg{
    position: relative;
    margin-top: 30px;
    background: #fff;
    height: 100%;
    padding: 20px 40px;
    border-radius: 10px; 
    box-shadow: 1px 1px 6px 2px rgb(0 0 0 / 20%);
}
.AppTitle{
    display: inline-table;
    position: absolute;
    background: #fff;
    right: 0;
    z-index: 1;
    top: -25px;
    padding:14px 60px;
    border: solid 1px #35386D;
    border-radius: 6px;
    left: 0;
    margin: 0 auto;
    box-shadow: 1px 1px 6px 2px rgb(0 0 0 / 20%);
}
.AppTitle h1{
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 20px;
    color: #35386D;
}
.btn.widthBtn{
    width: 160px;
    height: 50px;
}
.frmPoatRlvt{
    position: relative;
    top:-170px;
}
.refImg{

}
.refImg img{
    width: 44px;    
}
.page-button.active{
    padding: 10px 20px !important;
    background: #35386D !important;
    color: #fff !important;
}
.page-button{
    background: #fff;
    border: solid 1px #35386D !important;
    color: #35386D !important;
    padding: 10px 20px !important;
}
.MrgTop{
    margin-top: 32px;
}
.fromFilesCap {
    height: 45px;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 20%);
}
.form-control:focus{
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0) !important;
    border: solid 1px #dee2e6 !important; 
}
.all-dropselect{
    height: 46px;
    padding:10px !important;
}
.btn.fltrbtn{
    margin-top: 6px;
    padding: 0;
    background: transparent;
    border: none;
}
.btn.fltrbtn img{
    width: 30px;
}
.col-container {
    display: table; /* Make the container element behave like a table */
    width: 100%; /* Set full-width to expand the whole page */
  }
  .fromFilesclce{
    padding: 10px !important;
    height: 46px;
    border: solid 1px #dee2e6 !important;
    outline: none;
  } 

  .btn.addBtn {
    border: solid 1px #35386D !important;
    background-color: #35386D;
    color: white;
    font-size: 16px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.btn.addBtn:hover {
    border: solid 1px #35386D;
    color: #35386D;
    background-color: #fff;
}
.btn.addBtnpls {
    border: solid 1px #FF485B  !important;
    background-color: #FF485B;
    color: white;
    font-size: 16px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.btn.addBtnpls:hover {
    border: solid 1px #FF485B;
    color: #FF485B;
    background-color: #fff;
}

  .fromFilesclce:focus{
    border: solid 1px #dee2e6 !important;
  }
  .col {
    display: table-cell; /* Make elements inside the container behave like table cells */
  }
.MainDiv{
}
.tmcTitle h1{
    padding: 0;
    color: #FF485B;
    font-size: 26px;
    margin-bottom: 30px;
}
.tmcList{
    color: #8A8787;
    font-size: 18px;
}
.tmcList ul{
    margin: 0;
    margin-left: 20px;
    padding: 0;
}
.tmcList li{
    margin: 0;
    padding:10px 0;
}
.lgnnvry{
    margin-bottom: 30px;
}
.lgnnvry img{
    width: 150px;
}
.imp{
    color: #FF485B;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media only screen and (max-width : 767px) {
.btn.loginBtn{
     margin-right: 0px;
}
}