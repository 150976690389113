.captcha-container {
    display: flex;
    align-items: center;
  }
  
  .captcha-input {
    margin-right: 10px; /* Adjust spacing as needed */
    flex: 1;
  }
  
  .captcha-display {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Adjust spacing as needed */
    border: 1px solid #ccc; /* Box border */
    padding: 10px; /* Padding inside the box */
    border-radius: 4px; /* Rounded corners */
    background-color: #f9f9f9; /* Background color */
  }
  
  .captcha-text {
    margin-right: 10px; /* Adjust spacing as needed */
    font-size: 18px; /* Font size for visibility */
    font-weight: bold; /* Bold text */
  }
  
  .refresh-btn {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .verify-btn {
    margin-left: 10px; /* Adjust spacing as needed */
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  .modalp {
    font-size: 18px;
  }