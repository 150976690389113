/* Sidebar.css */

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?pkbljs');
  src:  url('fonts/icomoon.eot?pkbljs#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?pkbljs') format('truetype'),
    url('fonts/icomoon.woff?pkbljs') format('woff'),
    url('fonts/icomoon.svg?pkbljs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-applications:before {
  content: "\e900";
}
.icon-dashboard:before {
  content: "\e901";
}
.icon-generic:before {
  content: "\e902";
}
.icon-setup:before {
  content: "\e903";
}
.icon-university:before {
  content: "\e904";
}


.main-container {
/*  display: flex;*/
  height: 100vh;
  background: #eee;
}

#menu-btn {
  position: absolute;
  top: 15px;
  left:270px;
  z-index: 1000;
  background-color: #fff;
  color: #35386D;
  border: none;
  border-radius: 0px 4px 4px 0px;
  border-radius: 4px 4px 4px 4px ;
  padding: 3px 8px 3px 6px;
  cursor: pointer;
  font-weight: 600;
}

.sidebar {
  position: fixed;
  top: 0;
  left:0px;
  width: 270px;
  height: 100%;
  background-color: #fff;
  transition: 0.5s;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 20%);
  padding: 0;
  transition: all 0.3s;
}
.scrollbar{
  max-height: 100vh;
  overflow: auto !important;
  padding: 20px;
}
.scrollbar::-webkit-scrollbar-track,
.scrollbar::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #D3D3D3;
}

.scrollbar:-webkit-scrollbar-thumb {
  background-color: #a7a9ac;
  border-radius: 4px;
}
.sidebar-toggle{
  top:10px;
  line-height: 0px;
  position: absolute;
  right: -24px;
  background: #35386d;
  color: #fff;
  border: navajowhite;
  padding: 6px 6px;
  border-radius:0px 4px 4px 0px;
}

.sidebar.active-nav {
  left: -270px;
  top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  text-align: left;
  cursor: pointer;
  color: #35386D;
  margin: 20px 0;
  border: solid 1px #fff;
  
}

.sidebar ul li a {
  text-decoration: none;
    color: #8A8787;
    display: block;
    transition: all 0.3s;
    border-radius: 6px;
    padding: 10px 20px;
    border: solid 1px rgb(255 255 255 / 10%);
    box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 0%);
}

.sidebar ul li a:hover {
  border-radius: 6px;
  color: #35386D;
  border: solid 1px #35386D;
  padding: 10px 20px;  
  box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 10%);
}

.sidebar ul li a.active {
    border-radius: 6px;
    color: #fff;
    padding: 10px 20px;
    border: solid 1px #E8EAF6;
    background:#35386D;
}

.content {
  background: #eee;
  margin-left: 270px;
  padding: 30px;
  transition: all 0.3s;
  flex-grow: 1;
}

.content1 {
  background: #eee;
  /* margin-left: 270px; */
  padding: 30px;
  transition: all 0.3s;
  flex-grow: 1;
}

.content.active-cont {
  margin-left: 0px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 1;
}

.overlay.active {
  opacity: 1;
  pointer-events: auto;
}
/* Mobile responsiveness */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    left: -100%;
  }

  .sidebar.active-nav {
    width: 90%;
    left: 0;
    height: 100%;
  }
  .sidebar-toggle{
    right: -30px;
  }
  .content {
    margin-left: 0;
  }

  .content.active-cont {
    margin-left: 0;
  }

  #menu-btn {
    top: 15px;
    left: 15px;
  }

  .sidebar ul li {
    padding: 10px;
    text-align: left;
  }
}
@media only screen and (max-width : 767px) {
.content{
  padding: 15px;
}
}


.sidebar li:nth-child(2) a::before {
  content: "\e901";
  font-family: 'icomoon' !important;
  margin: 0 auto;
  font-size: 20px;
  float: left;
  margin-top: -4px;
  margin-right: 10px;
  margin-left: -6px;
}
.sidebar li:nth-child(3) a::before {
  content: "\e904";
  font-family: 'icomoon' !important;
  margin: 0 auto;
  font-size: 26px;
  float: left;
  margin-top: -6px;
  margin-right: 10px;
  margin-left: -10px;
}
.sidebar li:nth-child(4) a::before {
  content: "\e902";
  font-family: 'icomoon' !important;
  margin: 0 auto;
  font-size: 26px;
  float: left;
  margin-top: -6px;
  margin-right: 10px;
  margin-left: -10px;
}
.sidebar li:nth-child(5) a::before {
  content: "\e903";
  font-family: 'icomoon' !important;
  margin: 0 auto;
  font-size: 26px;
  float: left;
  margin-top: -8px;
  margin-right: 10px;
  margin-left: -10px;
}
.sidebar li:nth-child(6) a::before {
  content: "\e900";
  font-family: 'icomoon' !important;
  margin: 0 auto;
  font-size: 26px;
  float: left;
  margin-top: -8px;
  margin-right: 10px;
  margin-left: -10px;
}
.sidebar li:nth-child(7) a::before {
  content: "\e902";
  font-family: 'icomoon' !important;
  margin: 0 auto;
  font-size: 26px;
  float: left;
  margin-top: -8px;
  margin-right: 10px;
  margin-left: -10px;
}