/* src/pages/UniversitySetupPage.css */

body {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    margin: 0;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table thead th {
    background-color: #35386D;
    color: white;
    padding: 10px;
}

.table tbody td {
    padding: 10px;
    border: 1px solid #ddd;
}

.table img {
    max-width: 50px;
    height: auto;
}

.BxAllSide {
    padding: 20px;
}

.BxApple {
    padding: 40px;
    box-shadow: 0px 2px 8px 4px rgb(0 0 0 / 20%);
    background-color: #fff;
}

.AdinTitle h1 {
    font-size: 26px;
    font-weight: 500;
    color: #35386D;
    margin-bottom: 20px;
}

.AdinTitle h3 {
    font-weight: 500;
    color: #35386D;
    font-size: 24px;
}

.add-button, .edit-button, .delete-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
}

.add-button {
    background-color: #FF485B;
    color: white;
    width: 100%;
}

.add-button:hover {
    background-color: #e03b4e;
}

.edit-button, .delete-button {
    background-color: #ffc107;
    color: black;
}

.edit-button:hover {
    background-color: #e0a800;
}

.delete-button:hover {
    background-color: #e0a800;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.btn.saveBtn {
    border: solid 1px #FF485B !important;
    background-color: #FF485B;
    color: white;
    font-size: 16px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.btn.previousbtn{
    border: solid 1px #35386D !important;
    background-color: #fff;
    color: #35386D;
    font-size: 16px;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
}
.btn.previousbtn:hover{
    border: solid 1px #35386D !important;
    background-color: #fff;
    color: #35386D;
    font-size: 16px;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
}
.btn.saveBtn:hover {
    border: solid 1px #FF485B;
    color: #FF485B;
    background-color: #fff;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 20%);
}
.btn.CancelBtn {
    background-color: #fff;
    color: #35386D;
    font-size: 16px;
    border: 1px solid #35386D;
    padding: 10px 20px;
    cursor: pointer;
}

.btn.CancelBtn:hover {
    background-color: #35386D;
    color: #fff;
}
.university-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .university-table th,
  .university-table td {
    border-bottom: 1px solid #35386D;
    border-top: 1px solid #35386D;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
  
  .university-table th {
    background-color: #35386D;
    color: #fff;
    font-size: 14px !important;
    text-align: center;
  }
  
  .university-table tbody tr:hover {
    background-color: #f9f9f9;
  }
  .table-responsive::-webkit-scrollbar-track,
.table-responsive::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #D3D3D3;
}

.table-responsive:-webkit-scrollbar-thumb {
  background-color: #a7a9ac;
  border-radius: 4px;
}
.iconsw{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}