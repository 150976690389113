@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
    font-family: "Poppins", serif;
    font-size: 16px;
    margin: 0;
    background-color: #e5f1fa;
}

.brand {
    color: #FF485B;
    font-size: 30px !important;
}
.modal-header.modelheader{
    display: inline;
    position: relative;
    text-align: center;
    background:transparent;
    border-radius: 4px 4px 0px 0px ;
    border: none;
    margin-bottom: 0;
    padding-bottom: 0px;
}
.modal-title.modaltitle{    
    color: #FF485B;
    text-align: left;
    font-size: 26px;
    
}
.modal-content{
    border: none !important;
    border-radius: 4px !important;
}
.btn.saveHBtn{
    background: #fff;
    border: solid 1px #FF485B;
    padding: 10px 20px;
    border-radius: 6px;
    height: 46px;
    margin-left: 10px;
}
.droparrow-select{
    background: url(../img/down-arrow.png) no-repeat 98% center;
}
.fromFilespop{
    background-color: #fff !important;
}
.droparrow-select-course{
    border: solid 1px #ccc;
    width: 190px;
    margin: 0;
    padding: 8px 10px;
    border-radius: 4px;
    background: url(../img/down-arrow.png) no-repeat 98% center;
}
input::file-selector-button {
    height: 45px;
    background-image: linear-gradient(
      to right,
      #ededed 100%,
      #ededed 200%
    );
    background-position-x: 0%;
    background-size: 200%;
    border: 0;
    border-radius: 4px;
    color: #000 !important;
    padding:50px;
    transition: all 0.25s;
  }
  input::file-selector-button:hover {
    background-position-x: 100%;
    transform: scale(1.1);
  }
  .mt-mb{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mt3-mb2{
    margin-top: 30px;
    margin-bottom: 20px;
  }
.btn-close.btnclose{
    background: #FF485B;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 1;
    outline: none;
    line-height: 17px;
    font-size: 24px;
    padding: 4px !important;
    border-radius: 4px;
}
.fromFilespop {
    border-radius: 6px;
    height: 45px;
    font-size: 14px !important;
    color: #878787 !important;
}




.modal-body.model-popupPad{
    padding: 30px;
}
.btn.login {
    border-color: #FF485B;
    color: #FF485B;
    font-size: 16px;
    padding: 8px 15px;
}
.drklight{
    border: solid 1px #ededed;
    padding: 6px;
    border-radius: 4px;
    font-size: 14px;
}
.btn.btn-light{
    font-size: 14px;
    background: #ededed;
}
.btn.sigin {
    border-color: #35386D;
    color: #35386D;
    font-size: 16px;
    padding: 8px 15px;
}

.btn.login.logoutFill {
    background-color: #FF485B;
    color: #fff;
}

.loginPad {
    padding: 100px;
}

.fromBgBx {
    width: 600px;
    padding: 30px 130px 50px 130px !important;
    margin: 0 auto;
    position: relative;
    box-shadow: 0px 2px 8px 4px rgb(0 0 0 / 20%);
}

.radius {
    border-radius: 10px;
}

.datepicker.DatePcrPad {
    background: %PUBLIC_URL%/images/calendar.png no-repeat 96% 9px;
    width: 100%;
    margin: 0;
    border: 1px solid #ced4da;
    outline: none;
}

.fromFiles {
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 5%);
    height: 45px;
}

.fromTextarea {
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 5%);

}

.form-select-lg.fromDrop {
    height: 45px;
    line-height: 20px;
    font-size: 16px;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 5%);
}

.form-label {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
}

.formlabel {
    color: #1E1E1E;
    font-size: 14px;
    font-weight: 400;
}

.headingBr {
    position: relative;
    top: -60px;
    color: #35386D;
    background: #fff;
    padding: 10px 16px;
    width: 270px;
    margin: 0 auto;
    border-radius: 10px;
    border: #35386D solid 1px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.dis-in-flex{
    display: inline-flex;
}
.fl{
    float: left;
}
.fr{
    float: left;
}
.btn.submitBtn {
    font-size: 20px;
    font-weight: 500;
    background-color: #FF485B;
    height: 50px;
    line-height: 33px;
    color: #fff;
    border-radius: 4px;
    border: solid 1px #FF485B;
}

.btn.saveBtn {
    background: #FF485B;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    border-color: #FF485B;
    height: 50px;
    line-height: 30px;
    padding: 5px 30px;
    margin: 0px 10px;
}

.btn.saveBtn:hover {
    background: #fff;
    color: #FF485B;
    border-color: #FF485B;
}

.btn.CancelBtn {
    background: #fff;
    color: #35386D;
    font-size: 20px;
    font-weight: 400;
    border-color: #35386D;
    height: 50px;
    line-height: 30px;
    padding: 5px 30px;
    margin: 0px 10px;
}

.btn.CancelBtn:hover {
    border-color: #fff;
    background: #35386D;
    color: #fff;
}

.btn.addBtn {
    background: #35386D;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    border-color: #35386D;
    height: 50px;
    line-height: 30px;
    padding: 5px 30px;
    margin: 0px 10px;
}

.btn.addBtn:hover {
    border-color: #35386D;
    background: #fff;
    color: #35386D;
}
.womnaImg {
    position: absolute;
    left: -120px;
    z-index: 1;
    bottom: 0;
}

.womnaImg img {
    width: 100%;
}

.mnaImg {
    position: absolute;
    right: -280px;
    z-index: 1;
    bottom: -50px;
}

.mnaImg img {
    width: 100%;
}

.ProfleWrap {
    position: relative;
}

.ProfleDtls {
    margin-top: 40px;
}

.TabProgile {
    border: #35386d solid 1px;
    border-radius: 10px;
    margin:35px;
}

.hr {
    position: absolute;
    z-index: 0;
    width: 90%;
    left: 0;
    right: 0;
    top: 36px;
    margin: 0 auto;
    opacity: 1;
    background: #35386D;
}

.hrline {
    width: auto;
    background: #35386D;
    margin-bottom: 40px;
    height: 1px;
    margin-left: 20px;
    margin-right: 20px;
}
.last-cls{
    margin-right: 0px !important;

}
.nav-link .Proflealink {
    border: #35386D solid 1px;
    color: #35386D;
    padding: 14px 100px;
    font-size: 20px;
    font-weight: 500;
}

.nav-pills .nav-link.Proflealink {
    background-color: #fff;
    border: #35386D solid 1px;
    color: #35386D;
    padding: 14px 56px;
    font-size: 20px;
    font-weight: 500;
    margin-right: 100px;
    position: relative;
    opacity: 1 !important;
}

.nav-pills .nav-link.Proflealink.active,
.nav-pills .show>.nav-link.Proflealink {
    background-color: #35386D;
    padding: 14px 40px;
    position: relative;
}

.nav-pills .nav-link.Proflealink.Profleactive.active,
.nav-pills .show>.nav-link.Proflealink {
    background-color: #35386D;
    padding: 14px 100px;
    border: solid 1px #FF485B !important;
    color: #fff;
}
button.nav-link.tab-nav{    
    border: solid 1px #35386D;
}
.nav-tabs .nav-link{
    border-radius: 30px !important;    
    font-size: 14px !important;
    margin-left: 10px;
    padding: 5px 12px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background-color: #FF485B !important;
    color: #fff !important;
    border: solid 1px #FF485B !important;
    font-size: 14px !important;
    padding: 5px 12px;
}
button.nav-link.tab-nav.active {
    color: #fff !important;
}
.nav-tabs.ProfleBrd {
    border-bottom: 0px solid #ddd;
}

.tab-nav{
    color: #35386D !important;
}
.tab-nav.active {
    color: #8A8787 !important;
}
.tab-nav:hover{
    color:white !important;
}
.tab-nav.active:hover {
    color: #35386D !important;
}
.nav-tabs .nav-link.ProfleaSublink {
    background-color: #fff;
    border: #35386D solid 1px;
    color: #35386D;
    padding: 6px 30px;
    font-size: 16px;
    font-weight: 400;
    margin-right: 20px;
    border-radius: 50px;
}

.nav-tabs .ProfleaSublink {
    border: #FF485B solid 1px;
    color: #fff;
    padding: 6px 30px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 50px;
}

.nav-tabs .nav-link.ProfleaSublink.active,
.nav-tabs .show>.nav-link.ProfleaSublink {
    background-color: #FF485B;
    padding: 6px 30px;
    color: #fff;
    border-radius: 50px;
}

.nav-tabs .nav-item.show .nav-link.ProfleaSublink,
.nav-tabs .nav-link.ProfleaSublink.active.ProfleaSubActive {
    background-color: #FF485B;
    color: #fff;
    border-radius: 50px;

}

.tab-heading h1 {
    font-size: 26px;
    font-weight: 500;
    color: #35386D;
    margin: 0;
    padding: 0;
}

.manContTitle {
    text-align: left;
    color: #FF485B;
    font-size: 20px;
    font-weight: 500;
}

.NonContTitle {
    text-align: left;
    color: #35386D;
    font-size: 16px;
    font-weight: 500;
}

.MrgImg {
    margin-right: 30px;
}

.mb27 {
    margin-bottom: 27px !important;
}

@media only screen and (min-width: 768px) and (max-width: 980px) {
    .fromBgBx {
        width: 100%;
        padding: 30px 50px 50px 50px !important;
    }

    .mnaImg {
        width: 180px;
        position: absolute;
        right: -130px;
        z-index: 1;
        bottom: 0px;
    }

    .womnaImg {
        width: 160px;
    }

    .loginPad {
        padding: 50px 100px 50px 100px;
    }

    .mt-5.Mwebmt0 {
        margin-top: 0rem !important;
    }
}

@media only screen and (max-width : 767px) {
    .mnaImg {
        display: none;
    }

    .womnaImg {
        display: none;
    }

    .loginPad {
        padding: 70px 15px 40px 15px;
    }

    .fromBgBx {
        width: 100%;
        padding: 30px 30px 50px 30px !important;
    }

    .mt-5.Mwebmt0 {
        margin-top: 0rem !important;
    }
}