.customselect{
  width:15% !important;
  border:1px solid black !important;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-link {
  margin: 0 5px;
  padding: 8px 16px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.page-link.active {
  background-color: #007bff;
  color: white;
}

.page-link:hover {
  background-color: #f0f0f0;
}
.modal-content {
  overflow-y: unset !important;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content.small-modal {
  width: 300px; /* Adjust the width to make it smaller */
  max-width: 90%; /* Ensure it fits smaller screens */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 18px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-buttons .add-button,
.modal-buttons .cancel-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons .cancel-button {
  background-color: #6c757d;
}

.modal-buttons .add-button:hover,
.modal-buttons .cancel-button:hover {
  opacity: 0.9;
}

/* Dropdown container */
.dropdown {
  position: relative;
  width: 100%;
}

/* Dropdown Toggle Button */
.dropdown-toggle {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  background: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Arrow inside the button */
.dropdown-toggle::after {
  content: " ▼";
  font-size: 12px;
  margin-left: 8px;
}

/* Dropdown menu */
.dropdown-menu {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  padding: 5px 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* ✅ Clickable entire row */
.dropdown-item {
  margin-left: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
}

/* Ensure checkbox aligns well */
.form-check-input {
  margin-right: 8px;
}

/* ✅ Label and full row are clickable */
.form-check-label {
  cursor: pointer;
  user-select: none;
  flex-grow: 1;
}
