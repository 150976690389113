.application-list-container {
  padding: 20px;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters input,
.filters select {
  padding: 5px;
}

.application-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.application-table th,
.application-table td {
  border: none;
  border-top: 1px solid #35386d;
  border-bottom: 1px solid #35386d;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

.application-table th {
  background-color: #35386d;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.application-table tbody tr:hover {
  background-color: #f9f9f9;
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.pagination button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
}

.pagination button.active {
  background-color: #35386D;
  color: white;
  font-weight: bold;
}

.pagination button:hover {
  background-color: #35386D;
  color: white;
}
