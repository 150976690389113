@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: "Poppins", serif;
    background-color: #fff;
  }
  .containerWrap {
    padding: 40px;
    margin: 50px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .titleheader {
    background-color: transparent;
    color: #387cb0;
    padding: 0;
    text-align: left;
    border-bottom: solid 1px #387cb0;
  }
  .titleheader h2{
    color: #000;
  }
  .header {
    background-color: #387cb0;
    color: white;
    padding: 8px;
    text-align: left;
  }
  
  .content2 {margin-top: 30px;
  }
  
  .program-title {
    background-color: #e7e7e7;
    color: #000;
    padding: 10px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
  }
  
  .steps {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  
  .step {
    width: 22%;
    text-align: center;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .step-label {
    font-weight: bold;
    display: block;
    margin-top: 5px;
  }
  
  .completed {
    background-color: #35386d;
    color: white;
    position: relative;
  }
  
  .arrow {
    position: absolute;
    right: 10px;
    font-size: 20px;
    top: 20px;
  }
  
  .instructions {
    margin: 20px 0;
  }
  
  .instructions h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .instructions ul {
    list-style: none;
  }
  
  .instructions ul li {
    margin: 5px 0;
  }
  
  .apply-btn {
    text-align: right;
  }
  
  .apply-btn button {
    background-color: #1b5e20;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-right:10px;
  }
  .tab-content{
    background: #fff;
  }
  .apply-btn button:hover {
    background-color: #fff;
    border: solid 1px #1b5e20;
    color: #1b5e20;
  }
  .primary-btn, .primary-btn:hover {
    background-color: #35386D;
    border: 1px solid #35386D;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: #F9FAFC;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
    position: relative;
    max-height: 100%; /* or any desired height */
    overflow-y: auto;
  }
  .modal-content.previewcontent {
      width: 80%;
      overflow-y: auto !important;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: #ff485b;
    font-size: 16px;
    cursor: pointer;
    color: white;
    padding: 0px 5px;
  }

  .close-modal {
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .view-model{
    width:70%;
  }
  @media print {
    .col-lg-4 {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
    /* Additional print styles as needed */
  }
  .formbtn{
    background-color: #FF485B;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
  }
  .formtable {
    width: 100% !important;
    border-collapse: collapse !important;
  }
  
  .formtable th,
  .formtable td {
    border: none;
    border-bottom: 1px solid #35386d;
    border-top: 1px solid #35386d;
    padding: 10px;
    color: #8A8787;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
  .form-wrap.form-builder .frmb li.form-field:hover{
    box-shadow:none
  }
  .form-wrap.form-builder.formbuilder-embedded-bootstrap input{
    height: 45px;
  }
  .prev-holder input[type=text]{
    height: 45px;
  }
  .formtable th {
    background-color: #35386d;
    color: #fff;
    font-size: 14px !important;
    text-align: center;
  }
  .MuiPaper-root{
    box-shadow: none !important;
  }
  .formtable tbody tr:hover {
    background-color: #f9f9f9 !important;
  }