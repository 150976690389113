/* src/pages/AffiliationSetup.css */

body {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    margin: 0;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table thead th {
    background-color: #35386D;
    color: white;
    padding: 10px;
}

.table tbody td {
    padding: 10px;
    border: 1px solid #ddd;
}

.table img {
    max-width: 50px;
    height: auto;
}

.BxAllSide {
    padding: 20px;
}

.BxApple {
    padding: 20px;
    box-shadow: 0px 2px 8px 4px rgb(0 0 0 / 20%);
    background-color: #fff;
}

.AdinTitle h1 {
    font-size: 26px;
    font-weight: 500;
    color: #35386D;
    margin-bottom: 20px;
}

.add-button, .edit-button, .delete-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
}

.add-button {
    background-color: #FF485B;
    color: white;
    width: 100%;
}

.add-button:hover {
    background-color: #e03b4e;
}

.edit-button, .delete-button {
    background-color: #ffc107;
    color: black;
}

.edit-button:hover {
    background-color: #e0a800;
}

.delete-button:hover {
    background-color: #e0a800;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.btn.saveBtn {
    background-color: #FF485B;
    color: white;
    font-size: 16px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.btn.saveBtn:hover {
    background-color: #e03b4e;
}

.btn.CancelBtn {
    background-color: #fff;
    color: #35386D;
    font-size: 16px;
    border: 1px solid #35386D;
    padding: 10px 20px;
    cursor: pointer;
}

.btn.CancelBtn:hover {
    background-color: #35386D;
    color: #fff;
}

.back-arrow-btn {
    background-color: transparent;
    border: none;
    font-size: 45px;
    color: black;
    cursor: pointer;
    margin-left: 35px;
}
.back-arrow-btn img{
    width: 40px;
    margin-bottom: 20px;
}
.back-arrow-btn span {
    font-weight: bold;
}
.affiliation-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
.affiliation-table th {
    background-color: #35386d;
    color: #fff;
    font-size: 14px !important;
    text-align: center;
}
.affiliation-table th, .affiliation-table td {
    border: none;
    border-bottom: 1px solid #35386d;
    border-top: 1px solid #35386d;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}
  
  .affiliation-table tbody tr:hover {
    background-color: #f9f9f9;
  }