.side-navbar {
  width: 300px;
  height: 100%;
  position: fixed;
  margin-left: -300px;
  background-color: #fff;
  transition: 0.5s;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 20%);
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: #ffffff26;
}
.btn.updatebtn{
  padding:10px 20px;
  border: none;
  border: solid 1px #FF485B;
  background: #FF485B;
  font-size: 16px;
  color: #fff;
  border-radius:6px;
}
.btn.updatebtn:hover{
  padding:10px 20px;
  border: none;
  background: #fff;
  border: solid 1px #FF485B;
  font-size: 16px;
  color: #FF485B;
}
.my-container {
  transition: 0.4s;
  padding: 30px;
}
.hrline{
height: 1px;
background-color: #eee;
margin: 10px 0px;

} 
.rptPad{
padding-top: 100px;
} 
.reportTitle h1{
font-size: 40px;
color: #35386D;
font-weight: 500;
}
.modal-title{
color: #3C5372;
  font-size: 26px;
}
.dropdown-menu.show {
display: block;
box-shadow: 0px 2px 10px 1px rgb(0 0 0 / 20%);
border: none;
}
.cursor{
cursor: pointer;
}
.SearchBar{
height: 40px;
}
.userIcon{
margin-top: 5px;
}
.userIcon img{
width: 30px;
}
.active-nav {
  margin-left: 0;
}
/* UsersList.css */
.modalOverlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
display: none; /* Hidden by default */
z-index: 999;
}

.modalOverlay.active {
display: block; /* Display overlay when active */
}

.backgroundContainer {
background-color: #e9ecef; /* Background color for the container */
min-height: 100vh;
position: relative;
}
.active-cont {
  background-color: #eee;
  margin-left: 300px;
  padding: 30px;
  /* height: 100vh; */
}

/*#menu-btn {
  background-color: #fff;
  color: #000;
  margin-left: -65px;
  position: fixed;
  top: 30px;
  padding: 2px 6px;
  height: 28px;
}*/

.my-container input {
  border-radius: 2rem;
  padding: 2px 20px;
}
.alogo{
  text-align: center;
  margin-bottom: 20px !important;

}
.alogo img{
  width: 230px;
  padding: 0px 0px;
}
.radius6{
  border-radius: 6px;
}
.padAll{
  padding: 10px 30px ;
  height: 60px;
}
.BrTitlr{
  position: absolute;
}
.BrTitlr h1{
  color: #35386D;
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.BxAllSide{

}
.BxApple{
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 15%);
}
.bxActive{
  width: 100%;
  height: 100px;
  background-color: #0EB6CB;
  padding: 10px;
  color: #fff;
  font-size: 70px;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  line-height: 75px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}
.bxSumbAct{
  width: 100%;
  height: 100px;
  background-color: #46A24A;
  padding: 10px;
  color: #fff;
  font-size: 70px;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  line-height: 75px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}
.bxNotSumbAct{
  width: 100%;
  height: 100px;
  background-color: #FF8C05;
  padding: 10px;
  color: #fff;
  font-size: 70px;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  line-height: 75px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}
 
.bxTitle h1{
  color: #3C5372;
  font-size: 26px;
  text-align: left;
  font-weight: 500;
  line-height: 30px;
  margin-top: 18px;
  margin: 0;
  padding: 0;
}
.bxViewTitle{
  text-align: center;
 
}
.bxViewTitle a{
  text-align: center;
  color: #FF485B;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}
.BxWiseGrap{
  
}
.BxWiseGrap img{
  width: 100%;
  height: auto;
  
}
.StatueGrap{
  margin-top: 30px;
  
}
.StatueGrap img{
  width: 320px;
  height: auto;
  
}
.BxCrlOrg{
  background: #fff;
  width: 16px;
  height: 16px;
  display: inline-flex;
  border-radius: 100%;
  border: solid 3px #ff8c05;
  float: left;
  margin-right: 4px;
  margin-top: 3px;
}
.BxCrlGrn{
  background: #fff;
  width: 16px;
  height: 16px;
  display: inline-flex;
  border-radius: 100%;
  border: solid 3px #46a24a;
  float: left;
  margin-right: 4px;
  margin-top: 3px;
}
.BxCrlblu{
  background: #fff;
  width: 16px;
  height: 16px;
  display: inline-flex;
  border-radius: 100%;
  border: solid 3px #0eb6cb;
  float: left;
  margin-right: 4px;
  margin-top: 3px;
}
.BxTitleCr{
  color: #8A8787;
  font-size: 14px;
}
.nav-link{
  border-radius: 4px;
  color: #8A8787;
  font-size: 16px;
}
.nav-link a{
  background: #35386D;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  box-shadow: 0px 1px 10px 4px lch(0 0 0 / 0.2);
}
.nav-link.active{
  background: #35386D;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
/*  box-shadow: 0px 1px 10px 4px lch(0 0 0 / 0.2);*/
}
.nav-link:hover{
  background: #35386D;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
 /* box-shadow: 0px 1px 10px 4px lch(0 0 0 / 0.2);*/
}
.NavMrgRtl{
  margin-right: 6px;
}
.AdinTitle h1{
  color: #3C5372;
  font-size: 32px;
  padding: 0;
  margin: 0;
}
.table.adminTable {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table.adminTable>:not(:last-child)>:last-child>* {
border-bottom-color: currentColor;
color: #000;
font-weight: 400;
border-bottom: solid 1px #D9D9D9;
}
.table.adminTable>:not(caption)>*>* {
padding:20px 10px;
background-color: var(--bs-table-bg);
border-bottom-width: 1px;
box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
tbody, td, tfoot, th, thead, tr {
border-color: inherit;
border-style: solid;
border-width: 0;
color: #8A8787;
}
.p-6{
padding: 30px;
}
.btn.addUser{
background: #fff;
border: solid 1px #35386D;
color: #35386D;
font-size: 20px;
padding: 10px 20px;
font-weight: 500;
border-radius: 6px;
}
.BxApple{
  margin-bottom: 40px;
}
@media only screen and (max-width : 767px) {
  .BxApple{
    margin-bottom: 40px;
  }
  .BrTitlr{
    position: relative;
  }
  .BrTitlr h1{
    font-size: 22px;
    margin-bottom: 20px;
  }
  .padAll{
    padding: 20px;
    height: auto;
  }
  .active-cont{
    padding: 15px;
  }
  .sidebar.active-nav{
    z-index: 1;
  }
}






