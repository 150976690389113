/* src/index.css or src/App.css */
body {
    font-family: Arial, sans-serif;
  }
  
  .alert {
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    color: #31708f;
    padding: 15px;
    border-radius: 4px;
  }
  
  .bg-gray-100 {
    background-color: #f7fafc;
  }
  
  .bg-white {
    background-color: #fff;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .rounded-md {
    border-radius: 0.375rem;
  }
  
  .shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }

  /* src/App.css or src/index.css */

/* Example basic styles for formBuilder */
#fb-editor {
    border: 1px solid #ddd;
    padding: 20px;
  }
  
  .form-builder-component {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .form-builder-component .component-label {
    font-weight: bold;
  }
  
  .form-builder-component .component-content {
    margin-top: 5px;
  }

  
  /* src/components/FormBuilder.css */

.page-navigation {
    display: flex;
    margin-bottom: 10px;
  }
  
  .page-button {
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f7f7f7;
    cursor: pointer;
  }
  
  .page-button.active {
    background-color: #e0e0e0;
  }
  
  .add-page-button {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #d1e7dd;
    cursor: pointer;
  }


  #tabs {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  #tabs li {
    display: inline;
    margin-right: 5px;
  }
  
  .fb-editor {
    min-height: 300px; /* Ensure that editor has some height */
  }
  
  .save-all-wrap {
    margin-top: 20px;
  }
  
  
  